require("./src/preloaderAnimation.css");

exports.onInitialClientRender = () => {
  setTimeout(function () {
    document.getElementById("___loader").style.display = "none";
    document.body.style.overflowY = "visible";
  }, 2000);
  document.getElementById("___gatsby").style.display = "block";
  document.body.style.overflowY = "hidden";
};

const React = require("react");
const CookieBot = require("react-cookiebot/lib/CookieBot");
const domainGroupId = "f4caf55d-9510-42a6-abd0-e868b1e7e04b";

exports.wrapPageElement = ({ element }) => {
  return (
    <div>
      <CookieBot domainGroupId={domainGroupId} />
      {element}
    </div>
  );
};
